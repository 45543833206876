import * as React from "react";
import Layout from "../components/Layout";

const PoliticaPrivacidadePage = () => (
  <Layout>
    <div className="container section"> 
      <h1 style={{paddingTop: "128px", color: "black"}}>Política de Privacidade</h1>
      <p style={{paddingBottom: "64px"}}>
        A sua privacidade é importante para nós. É política do <a href="https://crisazevedoimoveis.com.br">Cris Azevedo Imóveis</a> respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site <a href="https://crisazevedoimoveis.com.br">Cris Azevedo Imóveis</a>, e outros sites que possuímos e operamos.<br /><br />
        
        Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.<br /><br />
        
        Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.<br /><br />
        
        Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.<br /><br />
        
        O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.<br /><br />
        
        Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.<br /><br />
        
        O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.<br /><br />
        
        <li>O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você.</li>
        
        <li>Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.</li>
        
        <li>Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados ​​por este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse.</li>
        
        <li>Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma compra.</li><br />
        
        <h2 style={{color: "black"}}>Compromisso do Usuário</h2>
        
        O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o <a href="https://crisazevedoimoveis.com.br">Cris Azevedo Imóveis</a> oferece no site e com caráter enunciativo, mas não limitativo:
        
        <li>A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</li>
        
        <li>B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica,  pix bet365 ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;</li>
        
        <li>C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do <a href="https://crisazevedoimoveis.com.br">Cris Azevedo Imóveis</a>, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.</li><br />
        
        <h2 style={{color: "black"}}>Mais informações</h2>
        Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.
      </p>
    </div>
  </Layout>
);

export default PoliticaPrivacidadePage;
